/* Navbar container */
.navbar>.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  z-index: 1000;
  margin: 0;
}
#skills {
  scroll-margin-top: 200px; /* Ndrysho këtë vlerë sipas nevojës */
}
#experience {
  scroll-margin-top: 200px; /* Ndrysho këtë vlerë sipas nevojës */
}
#portfolio {
  scroll-margin-top: 100px; /* Ndrysho këtë vlerë sipas nevojës */
}
/* Logo, links, and button alignment */
.navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

.hirebutton {
  margin-left: auto;
}

/* Hamburger menu button */
.navbar-toggler {
  border: none;
  outline: none;
  color: white;
}

/* Responsive behavior */
@media (max-width: 991.98px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .navbar .hirebutton {
    margin-left: auto;
    display: none;
  }

  .navbar-toggler {
    margin-left: auto;
  }
}
